<template>
  <section id="management">

    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h5 class="color-gray py-3">{{ title_component.subtitle }}</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <v-select 
              class="selvue-custom"
              :options="indicadores_list" 
              v-model="indicadores_selected"
              :clearable="false">
            </v-select>
          </div>
          <div class="col">
            <v-select 
              class="selvue-custom"
              :options="data_gerencias" 
              v-model="sel_gerencia"
              :clearable="false">
            </v-select>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
            <font-awesome-icon 
                icon="filter" 
                class="color-secondary pe-1"
              />
              Filtrar
          </div>
          <div class="col-12 col-lg-9 col-xl-8">
            <div class="row" v-click-outside="handleCalendar">
              <div class="col-6">
                <div 
                  class="form-control input-calendar" 
                  @click="openCalendar('start-date')">
                  <span>{{ dateFormatymd(init_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div 
                  class="form-control input-calendar disabled" 
                  v-if="init_date == 'Inicio'">
                  <span>{{ end_date }}</span>
    
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
                <div
                  class="form-control input-calendar"
                  v-if="init_date != 'Inicio'"
                  @click="openCalendar('end-date')">
                  <span>{{ dateFormatymd(end_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
              </div>
              <div class="col-12 position-relative">
                <div 
                  class="input-calendar-dropdown" 
                  :class="calendar_side" 
                  v-if="show_calendar">
                  <v-calendar
                    color="blue"
                    :max-date="max_date"
                    :min-date="min_date"
                    :attributes="attributes"
                    @dayclick="onDayClick"
                  />
                  <div 
                    class="border-arrow" 
                    :class="calendar_side">
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-3 mt-lg-0">
        <h5 class="font-title-bold color-gray">Reconocimientos totales</h5>
        <div class="card card-shadow border-round-10 border-0 mb-3" v-for="(data, i) in dataGerencias" :key="i">
          <div class="card-body">
            <h6 class="mb-0 color-gray">{{ data.nombre_valor }}</h6>
            <div class="d-flex align-items-center">
              <div class="progress flex-fill me-2">
                <div 
                  class="progress-bar" 
                  :style=" 'width: ' + data.percentage + '%;'+'background-color: ' + colores[i] + ';'"
                  aria-valuemin="0" 
                  aria-valuemax="100">
                </div>
              </div>
              <h5
                class="mb-0 font-title-bold" 
                :style="'color: ' + colores[i]">
                  {{ formatPercentage(data.percentage) }}%
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>


<!--     <div class="management">
      <div class="management-top">
        <div class="management-top-select">
          <div class="titletop">
          </div>
          <div class="select">
            <div class="select-container" :class="open_indicadores_select_list && 'active'">
              <div class="select-btn" @click="openSelectIndicadores">
                <div class="item">
                  {{ indicadores_selected }}
                </div>
                <div class="icon">
                  <font-awesome-icon :icon="open_indicadores_select_list ? 'angle-up' : 'angle-down'">
                  </font-awesome-icon>
                </div>
              </div>
              <div class="select-list" v-if="open_indicadores_select_list">
                <div v-for="(indi, k) in indicadores_list" :key="k" class="list-item" @click="selectIndicador(indi)">
                  {{ indi.label }}
                </div>
              </div>
  
            </div>
            <div class="select-container gerencia" :class="open_select_list == 'gerencia' && 'active'"
              @click="openSelect('gerencia')">
              <div class="select-btn">
                <div class="item">
                  {{ gerencia_selected.nombre_gerencia }}
                </div>
                <div class="icon">
                  <font-awesome-icon :icon="
                    open_select_list == 'gerencia' ? 'angle-up' : 'angle-down'
                  ">
                  </font-awesome-icon>
                </div>
              </div>
              <div class="select-list" v-if="open_select_list == 'gerencia'">
                <div v-for="(gerencia, k) in gerencia_list" :key="k" class="list-item" @click="selectGerencia(gerencia)">
                  {{ gerencia.nombre_gerencia }}
                </div>
              </div>
            </div>
          </div>
  
        </div>
        <div class="management-top-filter">
          <div class="title">Filtrar por período:</div>
  
          <div class="calendar-container d-flex" v-click-outside="handleCalendar">
            <div class="calendar" :class="calendar_side" v-if="show_calendar">
              <v-calendar color="blue" :max-date="max_date" :min-date="min_date" :attributes="attributes"
                v-model="date_init" @dayclick="onDayClick" />
              <div class="border-arrow" :class="calendar_side">
                <div class="arrow"></div>
              </div>
            </div>
            <div class="start" @click="openCalendar('start-date')">
              <span>{{ init_date }}</span>
              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
            </div>
            <div class="end unactive" v-if="init_date == 'Inicio'">
              <span>{{ end_date }}</span>
  
              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
            </div>
            <div class="end" v-if="init_date != 'Inicio'" @click="openCalendar('end-date')">
              <span>{{ end_date }}</span>
              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="management-container">
        <div class="content-title">Reconocimientos totales:</div>
        <div class="charts">
          <div class="service-bar" v-for="(data, i) in dataGerencias" :key="i">
            <div class="bar-title">{{ data.nombre_valor }}</div>
            <div class="bar-container">
              <div class="progress">
                <tippy arrow followCursor="initial" trigger="click" class="tippy-container">
                  <template v-slot:trigger>
                    <div id="progress-service"
                      :style=" 'width: ' + data.percentage + '%;'+'background-color: ' + colores[i] + ';'"></div>
                  </template>
                  <div class="recon-num">
                    <span>N° reconocimientos</span> <br />
                    <span>{{ data.cantidad }} </span>
                  </div>
                </tippy>
              </div>
              <div class="percentage" :style="'color: ' + colores[i]">
                {{ formatPercentage(data.percentage) }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </section>

</template>

<script>
//import SectionTitle from "../Section/SectionTitle.vue";
import { GET_GERENCIAS, GET_GERENCIAS_DATA } from "@/apollo/queries";
import SectionTabs from "../Section/SectionTabs.vue";

export default {
  components: {
    //SectionTitle
    SectionTabs,
  },
  data() {
    return {
      tab_component: [
        {
          name: "Dashboard",
          route: "recognize-Dashboard",
        },
        {
          name: "Indicadores",
          route: "recognize-management",
        },
      ],
      indicadores_list: [
        { label: "Año", route: "recognize-year" },
        { label: "Gerencia", route: "recognize-management" },
        { label: "Planta", route: "recognize-age" },
        { label: "Top 20", route: "recognize-ranking" },
        { label: "Consultas por colaborador", route: "recognize-contributor" },
        { label: "Detalles de reconocimiento", route: "recognize-reports" },
      ],
      indicadores_selected: "Gerencia",
      open_indicadores_select_list: false,

      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      title_component: {
        title: "Reconoce",
        subtitle: "¡Conoce el impacto de Reconoce! Podrás revisar cifras anuales, por gerencia, top 20, lugar de trabajo y colaborador.",
      },
      open_select_list: "",
      year_list: ["2022","2023", "2024", "2025"],
      year_selected: "2022",
      month_list: [
        { nombre: "Enero", id: "1" },
        { nombre: "Febrero", id: "2" },
        { nombre: "Marzo", id: "3" },
        { nombre: "Abril", id: "4" },
        { nombre: "Mayo", id: "5" },
        { nombre: "Junio", id: "6" },
        { nombre: "Julio", id: "7" },
        { nombre: "Agosto", id: "8" },
        { nombre: "Septiembre", id: "9" },
        { nombre: "Octubre", id: "10" },
        { nombre: "Noviembre", id: "11" },
        { nombre: "Diciembre", id: "12" },
      ],
      month_selected: { nombre: "Enero", id: "1" },
      gerencia_list: [{ id_gerencia: "0", nombre_gerencia: "Todas" }],
      gerencia_selected: { id_gerencia: "0", nombre_gerencia: "Todas" },
      dataGerencias: [],
      colores:["#357BF3","#23BE61","#F26F28","#EC206B"],
      show_calendar: false,
      calendar_side: "",
      init_date: "Inicio",
      end_date: "Término",
      max_date: "",
      min_date: "",
      img_calendar: require("@/assets/img/utilities/calendar.svg"),

      //FF
      sel_gerencia: { id: "0", type: "Todas", label: "Todas" },
      data_gerencias: [{ id: "0", type: "Todas", label: "Todas" }],
    };
  },
  computed: {
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },
watch: {
  init_date(){
    this.getGerenciasData();
  },
  end_date(){
    this.getGerenciasData();
  },
  sel_gerencia() {
      this.getGerenciasData();
  },
  indicadores_selected(value) {
    this.$router.push({ name: value.route });
  }
},
created() {
  this.getGerencias(this.id_empresa);
},
  mounted() {
    //this.getGerencias(this.id_empresa);
    this.end_date = this.dateNewFormat(new Date(),1);
    this.init_date = this.dateNewFormat(this.addDays(new Date(), -30),1) 
    this.getGerenciasData();
  },
  methods: {
    getGerencias(id) {
      this.gerencia_list = [{ id_gerencia: "0", nombre_gerencia: "Todas" }];
      this.$apollo
        .query({
          query: GET_GERENCIAS,
          variables: {
            id_empresa: id,
          },
        })
        .then((res) => {
          var resp = res.data.gerencias;
          //console.log('GET_GERENCIAS',resp);
          resp.forEach((element) => this.gerencia_list.push(element));
          resp.forEach((element) => this.data_gerencias.push({
            id: element.id_gerencia,
            type: element.__typename,
            label: element.nombre_gerencia
          }));
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getGerenciasData() {
      this.gerencia_list = [{ id_gerencia: "0", nombre_gerencia: "Todas" }];
      /* year: this.year_selected,
      month: this.month_selected.id, */
      this.$apollo
        .query({
          query: GET_GERENCIAS_DATA,
          variables: {
            id_empresa: this.id_empresa,
            fecha_inicio: this.init_date,
            fecha_final: this.end_date,
            //id_gerencia: this.gerencia_selected.id_gerencia,
            id_gerencia: this.sel_gerencia.id,
          },
        })
        .then((res) => {
          var resp = res.data.reconocimientosGerenciasYearMonth;
          //console.log("GET_GERENCIAS_DATA", resp);
          this.dataGerencias = resp;
          this.dataPercentage(resp);
          //console.log(this.dataGerencias);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    dataPercentage(d) {
      var total = 0;
      d.forEach(function (element) {
        total = total + Number(element.cantidad);
      });
      d.forEach(function (element) {
        var el = Math.round((100 * Number(element.cantidad)) / total);
        element.percentage = isNaN(el) ? 0 : el;
      });

      //console.log(total);
    },
    openSelect(item) {
      if (item == this.open_select_list) {
        this.open_select_list = "";
      } else {
        this.open_select_list = item;
      }
    },
    selectYear(year) {
      this.year_selected = year;
      setTimeout(() => {
        this.open_select_list = "";
      }, 400);
      this.getGerenciasData();
    },
    selectMonth(month) {
      this.month_selected = month;
      setTimeout(() => {
        this.open_select_list = "";
      }, 400);
      this.getGerenciasData();
    },
    selectGerencia(gerencia) {
      this.gerencia_selected = gerencia;
      setTimeout(() => {
        this.open_select_list = "";
      }, 400);
      this.getGerenciasData();
    },
    formatPercentage(p) {
      if (p % 1 == 0) {
        return p;
      } else {
        return p.toFixed(1);
      }
    },
    openSelectIndicadores() {
      this.open_indicadores_select_list = !this.open_indicadores_select_list;
    },
    selectIndicador(indi) {
      if (this.indicadores_selected != indi.name) {
        this.$router.push({ name: indi.route });
        this.indicadores_selected = indi.name;
      }
      this.open_indicadores_select_list = false;
    },
    
    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
        this.date_init =
          this.init_date != "Inicio" ? new Date(this.init_date) : "";
        this.max_date = this.end_date == "Término" ? "" : this.end_date;
        this.min_date = "";
      } else {
        var min = new Date(this.init_date);
        this.min_date = min.setDate(min.getDate() + 1);
        this.max_date = "";
        this.min_date = this.init_date == "Inicio" ? "" : this.init_date;
      }
    },
    onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.init_date = day.id;
      } else {
        this.end_date = day.id;
      }
      if (this.init_date != "Inicio" || this.end_date != "Término") {
        this.getGerenciasData();
      }
      this.show_calendar = false;
    },
    handleCalendar() {
      // console.log("click outside calendar");
      this.show_calendar = false;
    },
    addDays(date, days) {
      var result = date;
      result.setDate(result.getDate() + days);
      return result;
    },
    dateNewFormat(date,type) {
      var this_day = date;
      var day = this_day.getDate();
      var month = this_day.getMonth();

      if(type == 1){
      return `${this_day.getFullYear()}-${
        month + 1 < 10 ? `0${month+ 1}` : month+ 1 
        }-${day + 1 < 10 ? `0${day+ 1}` : day+ 1}`;
      }
    },
  },
};
</script>
